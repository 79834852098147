<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>
              Week of
              <b>{{ formatDate(week.start, "E, do MMM") }}</b> to
              <b>{{ formatDate(week.end, "E, do MMM") }}</b>
            </p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text bg-primary text-white font-weight-bold"
                  >
                    Farm
                  </span>
                </div>
                <Multiselect
                  :options="farms"
                  v-model="farm"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Type to search..."
                />
              </div>
            </li>
            <li>
              <a
                href="#newItem"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-plus-c"></em>
                <span>
                  <span class="d-none d-sm-inline-block">
                    Select
                  </span>
                  Week
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="newItem">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">
                        Select Date Range
                      </h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker value="range" v-model="week" is-range />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ formatDate(week.start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ formatDate(week.end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span>
                            Use Selected Dates
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card card-bordered px-3" v-else>
        <div class="rows">
          <ul class="nav nav-tabs d-print-none">
            <li
              class="nav-item"
              v-for="(d, i) in getDates(week.start, week.end)"
              :key="i"
            >
              <a
                class="nav-link"
                :class="{ active: i == day }"
                data-toggle="tab"
                :href="`#day${i}`"
                @click="setDay(i, d)"
              >
                {{ formatDate(new Date(d), "E, do MMM") }}
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              v-for="(d, i) in getDates(week.start, week.end)"
              :key="i"
              class="tab-pane"
              :id="`day${i}`"
              :class="{ active: i == day }"
            >
              <div class="ng-block ng-block-lg">
                <div class="ng-block-head px-4">
                  <div class="ng-block-head-content">
                    <h5 class="ng-block-title">
                      {{ formatDate(new Date(d)) }}
                    </h5>
                    <h6 v-if="farmName">For {{ farmName }}</h6>
                  </div>
                </div>
                <form class="card" @submit.prevent="saveForecast">
                  <div class="card-body">
                    <table class="table table-tranx">
                      <thead>
                        <tr class="tb-tnx-head">
                          <th class="tb-tnx-amount is-alt">
                            Product
                          </th>
                          <th>
                            Projected
                          </th>
                          <th>
                            Ammended
                          </th>
                          <th>Actual</th>
                          <th>
                            Packout
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(p, i) in products">
                          <tr class="tb-tnx-item" :key="i" v-if="form[p.id]">
                            <td class="tb-tnx-amount is-alt">
                              {{ p.name }}
                            </td>
                            <td>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[p.id]['projected']"
                              />
                            </td>
                            <td>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[p.id]['amend']"
                              />
                            </td>
                            <td>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[p.id]['actual']"
                              />
                            </td>
                            <td>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[p.id]['packout']"
                              />
                            </td>
                          </tr>
                        </template>
                      </tbody>
                      <tfoot class="d-print-none">
                        <th colspan="5">
                          <button class="btn btn-primary float-right">
                            Save
                            <span class="pl-1 d-none d-sm-block">
                              for
                              {{ formatDate(new Date(d), "do MMM") }}</span
                            >
                          </button>
                        </th>
                      </tfoot>
                    </table>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { defineComponent, computed, ref, reactive, onMounted } from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      name: "Name",
      code: "Code"
    };
    const form = ref({});

    const farms = ref([]);
    const farm = ref(1);
    const farmName = computed(() => {
      const n = farms.value?.find((f: any) => f.id == farm.value);

      return n?.["name"];
    });

    const products = ref({
      data: []
    });

    const product = reactive({
      name: "",
      code: ""
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const day = ref(0);
    const date = ref(new Date());

    const today = computed(() => new Date());
    const week = ref({
      start: new Date(),
      end: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000)
    });
    const masks = ref({
      input: "YYYY-MM-DD"
    });

    function createForm(a: any) {
      a.map((b: any) => {
        Object.defineProperty(form.value, b.id, {
          value: {
            projected: 0,
            amend: 0,
            actual: 0,
            packout: 0
          },
          writable: true,
          enumerable: true,
          configurable: true
        });
      });
    }

    function fetchProducts() {
      processing.value = true;
      http.get(`/api/products?fetch=all`).then(res => {
        products.value = res.data;
        processing.value = false;

        createForm(res.data);
      });
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    function createProduct() {
      http
        .post("/api/products", product)
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchProducts();
        });
    }

    function saveForecast() {
      Object.defineProperty(form.value, "details", {
        value: { farm: farm, date: date },
        writable: true,
        enumerable: true,
        configurable: true
      });

      http.post("/api/forecasts", form.value).then(res => {
        console.log(res.data);
      });
    }

    function updateProduct(product: any) {
      product._method = "PUT";
      http
        .post(`/api/products/${product.id}`, product)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchProducts();
        });
    }

    function deleteProduct(id: any) {
      http.post(`/api/products/${id}`, { _method: "DELETE" }).then(res => {
        fetchProducts();
      });
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function getDates(start: any, stop: any) {
      const dates = [];
      let currentDate = moment(start);
      const stopDate = moment(stop);
      while (currentDate <= stopDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function formatDate(d: any, f = "PPPP") {
      return format(d, f);
    }

    onMounted(() => {
      fetchProducts();
      fetchFarms();
    });

    return {
      headers,
      fetchProducts,
      processing,
      products,
      createProduct,
      created,
      product,
      updateProduct,
      updated,
      deleteProduct,
      formatDate,
      today,
      week,
      masks,
      getDates,
      setDay,
      day,
      date,
      form,
      farm,
      farmName,
      farms,
      saveForecast
    };
  }
});
</script>
